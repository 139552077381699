/* Fix to allow <!doctype html>, because otherwise html and body don't occupy whole screen */
html, body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.box .row.header {
  flex: 0 1 auto;
}

.box .row.content {
  flex: 1 1 auto;
}

.content {
  display: flex;
}

.sidebar {
  flex: 0 0 40%;
}

#mapContainer {
  flex: 1 5 auto;
}

.animal-image {
  cursor: pointer;
  background-color: #eee;
}

@mixin animal-image-status {
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
}

.records-list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.records-list {
  display: flex;
  flex: 1;
}

.records-list-header {
  background-color: #444;
  color: #fff;
}

.animal-image-status-lost {
  @include animal-image-status;
  background-color: #ef201b;
}

.animal-image-status-found {
  @include animal-image-status;
  background-color: #91de2b;
}

.fullPicturePreview {
  max-width: 100%;
  height: auto;
}

.page-with-text {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  max-width: 800px;
}

.welcome-text {
  text-align: justify;
  padding: 3px;
  white-space: pre-wrap;
}

.page-with-text-header {
  font-size: 40px;
  padding-top: 25px;
  margin: 0;
}

.credits {
  position: absolute;
  bottom: 0;
}

.big-wide-button {
  width: 100%;
  font-size: 200%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.big-button-cta {
  @extend .big-wide-button;
  border-radius: 10px;
  font-size: 125%;
  width: 80%;
}

label.checkbox-label {
  cursor: pointer;
  margin: 0 0 0 20px;
  display: inline-block;
}

.checkbox-label > input {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}

.event-type-checkboxes {
  display: inline-block;
}

#hide-sidebar {
  float: right;
}

#show-sidebar {
  float: left;
  height: 100%;
}

.googleMapContainer {
  width: 100%;
  height: 100%;
}

#center-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  height: 40px;
  width: 40px;
  border: 2px solid #88f;
  border-radius: 50%;
  background-color: rgba(30, 144, 255, 0.2);
  box-sizing: border-box;
  pointer-events: none;
}

.google-map-parent {
  width: 100%;
  position: relative;
  display: inline-block;
}

#cachedMapContainer {
  width: 100%;
  height: 100%;
}

.dd-menu {
  float: right;
}

#date-range, .event-type-checkboxes {
  margin: 3px;
}

.nav-buttons {
  float: right;
  margin: 5px;
}

.button-link {
  background: none;
  margin: 0 3px;
  color: #666;
}

.button-link-active {
  font-weight: bold;
}

.nav-menu-item-active {
  font-weight: bold;
}

.footer {
  margin-top: 60px;
}

/* todo make it mobile-first */
@media screen and (max-width: 1024px) {
  .googleMapContainer {
    height: 100%;
  }

  .pure-hidden-md {
    display: none;
  }

  .big-wide-button {
    font-size: 125%;
  }

  .page-with-text {
    margin: 30px 10px 0;
  }

  .page-with-text-header {
    font-size: 30px;
  }

  .big-wide-button {
    border-radius: 0;
  }

  .big-button-cta {
    width: 100%;
  }
}
